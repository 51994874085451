/**
 * Request a analogy from the Addition API
 * @param    {List} top    Top of the problem
 * @param    {List} bottom    Bottom of the problem
 * @param    {List} corrections    submitted corrections of the problem
 * @param    {List} result    Result of the problem
 * @return   {top, bottom, correction, result}   Information about a analogy, if the result/correction was wrong
 *
 *
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel, Eva Jansohn
 * @date: 2021-03-30
 */ 
export async function getAnalogy(
  top,
  bottom,
  corrections,
  result,
) {
  
  const problem = [];
  const solution = [];

  for (let i = 0; i < top.length; i++) {
    problem.push([top[i], bottom[i], '']);
    solution.push([corrections[i], bottom[i], result[i]]);
  }
  
  let body = {
    problem: problem,
    solution: solution,
  };

  const response = await postData('/its/get_analogy', body);

  let an = response.analogy;
  let an_sol = response.solution;

  let an_top = [];
  let an_bottom = [];
  let an_correction = [];
  let an_result = [];
  

  for (let d = 0; d < an.length; d++) {
    
    an_top.push(an[d][0]);
    an_bottom.push(an[d][1]);
    an_correction.push(an_sol[d][0]);
    an_result.push(an_sol[d][2]);
  }

  return {
    top: an_top,
    bottom: an_bottom,
    correction: an_correction,
    result: an_result,
  };
}

/** 
 * Request a diagnosis from the Addition API
 * @param    {List} top    Top of the problem
 * @param    {List} bottom    Bottom of the problem
 * @param    {List} corrections    submitted corrections of the problem
 * @param    {List} result    Result of the problem
 * @return   {correct, spec_error, error, column, correct_val}  Diagnosis of the given problem
 */
export async function getDiagnosis(
  top,
  bottom,
  corrections,
  result,
) {
  const problem = [];
  const solution = [];
  //problem.push([0, 0, '']);
  //solution.push([0, 0, 0]);
  for (let i = 0; i < top.length; i++) {
    problem.push([top[i], bottom[i], '']);
    solution.push([corrections[i], bottom[i], result[i]]);
  }

  let body = {
    problem: problem,
    solution: solution,
  };
  const response = await postData('/its/get_diagnosis', body);

  let correct = true;
  let error = '';
  let column = -1;
  let correct_val = -1;
  if (Array.isArray(response)) {
    correct = false;
    error = response[1];
    column = response[2];
    correct_val = response[3];
  } else {
    error = '';
  }
  return {
    correct: correct,
    spec_error: response[0],
    error: error,
    column: column,
    correct_val: correct_val,
  };
}

async function postData(url = '', data = {}) {
  const response = await fetch( url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
}

import React from "react";
import "../CSS/ImpressumLink.css";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel
 * @date: 2021-03-30
 */
class ImpressumLink extends React.Component {
  constructor(props) {
    super(props);

    this.openImpressum = this.openImpressum.bind(this);
  }

  openImpressum() {
    this.props.history.push("/ITS/impressum");
  }

  /**
   * @returns An Impressum link at the bottom left corner
   */
  render() {
    return (
      <button
              className="impressumLink"
              onClick={this.openImpressum}>
      Impressum
      </button>
    );
  }
}

export default withRouter(ImpressumLink);
ImpressumLink.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

import React from "react";
import "../../CSS/AdditionPanel.css";
import ClickableNumber from "./ClickableNumber.js";
import Number from "./Number";
import InputNumber from "./InputNumber";
import CorrectButton from "./CorrectButton.js";
import RefreshButton from "./RefreshButton.js";
import PropTypes from "prop-types";
import CorrectionLine from "./CorrectionLine";
import { getAnalogy, getDiagnosis } from "../../shared/PrologConnector.js";

/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel, Eva Jansohn
 * @date: 2021-03-30
 */
class AdditionPanel extends React.Component {
  constructor(props) {
    super(props);

    // init states
    this.state = {
    
      corrections_crossedOut: new Array(this.props.top.length +1).fill(false),
      corrections_row: new Array(this.props.top.length).fill(null),
      result_row: new Array(this.props.top.length).fill(null),
      error_message: null,
      corrections_row_error: new Array(this.props.top.length).fill(false),
      result_row_error: new Array(this.props.top.length).fill(false),
      add_one: new Array(this.props.top.length +1).fill(false),
    };
    
    this.resultOnChange = this.resultOnChange.bind(this);
    this.correctionsOnChange = this.correctionsOnChange.bind(this);
  }

  /**
   * Correct the submitted calculation and returns the analogy + diagnosis
   * @return {diagnosis, analogy}: diagnosis and analogy as arrays
   */
  async getAnalogyAndDiagnosis() {
    
    const result = this.getResult();
    if (result.error_message) {
      this.setState({
        error_message: result.error_message,
      });
      return;
    }
    
    this.setState({
      result_row_error: Array(this.props.top.length).fill(false),
    });

    const corrections = this.getCorrections();
    if (corrections.error_message) {
      this.setState({
        error_message: corrections.error_message,
      });
      return;
    }
    this.setState({
      corrections_row_error: Array(this.props.top.length).fill(false),
      error_message: null,
    });
    
    const top = this.getTop();
    const bottom = this.getBottom();

    let diagnosis = await getDiagnosis(
      top,
      bottom,
      corrections.corrections,
      result.result
    );
 
    let analogy = {};
    if (!diagnosis.correct) {
    
      analogy = await getAnalogy(
        top,
        bottom,
        corrections.corrections,
        result.result
      );
     
    }
    

    return { diagnosis: diagnosis, analogy: analogy };
  }

  /**
   * Checks the result row for input errors and returns it
   * @returns {result, error_message} the result row as string array and a error_message
   */
  getResult() {
    let result = [];
    let error_message = null;
    for (let i = 0; i < this.props.top.length +1; i++) {
      const value = document.getElementsByClassName("result" + i)[0].value;
      if (value === "") {
        result.push(parseInt(0));
      } else {
        var num;
        num = parseInt(value);
        
        if (isNaN(num)) {
          error_message = "Achtung: Gib nur Zahlen ein.";
          this.setValidationErrorInResultRow(i);
          break;
        }
        result.push(num);
      }
    }
   
    return { result: result, error_message: error_message };
  }

  /**
   * Returns the bottom
   * @returns the bottom row as string array and a error_message
   */
  getBottom() {
    let bottom = [];
    bottom.push(parseInt(0));
    for (let i = 0 ; i < this.props.top.length ; i++) {
      const value = document.getElementsByClassName("bottom" + i)[0]
        .textContent;
      if (value === "") {
        bottom.push(parseInt(0));
      } else {
        const num = parseInt(value);
        bottom.push(num);
      }
    }
    
    return bottom;
  }

  /**
   * Checks the top row for input errors and returns it
   * @returns the top row as string array and a error_message
   */
  getTop() {
    let top = [];
    top.push(0); //include this????????
    for (let i = 0; i < this.props.top.length; i++) {
      const value = document.getElementsByClassName("top" + i)[0]
        .textContent;
      
        const num = parseInt(value);
        top.push(num);
      
    }
    return top;
 
  }

  /**
   * Checks the corrections row for input errors and returns it
   * @returns {corrections, error_message} the correction row as string array and a error_message
   */
  getCorrections() {
    let corrections = [];
    let error_message = null;
    var num;
    

    if(this.state.add_one[0] == true){
      
      corrections.push(1);
  }
  else{
   
      corrections.push(0);
  }
   for (let i = 0; i < this.props.top.length +1 ; i++) { 
      if (this.state.add_one[i+1]) {   
        num = parseInt(this.props.top[i]) +1;
      } 
      else{
        num = parseInt(this.props.top[i]);
      }
     
      corrections.push(num);
    }
    return { corrections: corrections, error_message: error_message };
  }


  /**
   * Sets a error on a column i in the result row
   * @param {*} i: index of the column
   */
  setValidationErrorInResultRow(i) {
    let new_row = this.state.result_row_error;
    new_row[i] = true;
    this.setState({
      result_row_error: new_row,
    });
  }

  /**
   * Sets a error on a column i in the corrections row
   * @param {*} i: index of the column
   */
  setValidationErrorInCorrectionsRow(i) {
    let new_row = this.state.corrections_row_error.slice();
    new_row[i] = true;
    this.setState({
      corrections_row_error: new_row,
    });
  }

  /**
   * Changes a value and updates variables with a correction input
   * @param {SyntheticEvent} event: event triggered by a input
   */
 correctionsOnChange(/*event*/) { //dont want to be able to input numbers
   /* let value = event.target.value;
    let j = parseInt(event.target.classList[1].slice(-1));
    let new_row = this.state.corrections_row;
    new_row[j] = value;
    this.setState({ corrections_row: new_row });*/
  }

  /**
   * Updates variables when top column gets crossed out
   * @param {SyntheticEvent} event: event triggered by a click on top col
   */
  top_onClick(event, i) {
    event.preventDefault();
    let new_corrections_crossedOut = this.state.corrections_crossedOut.slice();
    new_corrections_crossedOut[i] = !new_corrections_crossedOut[i];
    if(this.props.highlighting[i] === 1){
   
    this.props.highlighting[i] = 0;
    this.state.add_one[i] = false;
        
    }
    else{
      this.props.highlighting[i] = 1;
      this.state.add_one[i] = true;
        
    }
    
    

   let new_correction_row = this.state.corrections_row;
    new_correction_row[i] = NaN;

    this.setState({
      corrections_crossedOut: new_corrections_crossedOut,
      correction_row: new_correction_row,
    });
  }

  /**
   * Changes a value and updates variables with a new result input
   * @param {SyntheticEvent} event: event triggered by a input
   */
  resultOnChange(event) {
    let value = event.target.value;
    let j = parseInt(event.target.classList[1].slice(-1));
    let new_row = this.state.result_row;
    new_row[j] = value;
    this.setState({ result_row: new_row });
  }

  /**
   * Resets the inputs in the Additionpanel
   * @param {*} event: Event triggered by the refresh-button click
   */
  reset(event) {
    if (event != null) event.preventDefault();

    this.setState({
      add_one: new Array(this.props.top.length)
      .slice()
      .fill(false),
      corrections_crossedOut: new Array(this.props.top.length)
        .slice()
        .fill(false),
      corrections_row: new Array(this.props.top.length).slice().fill(NaN),
      result_row: new Array(this.props.top.length).slice().fill(NaN),
    });
  }

  //************** Render functions **************\\

  
     
  /**
   * Configures and renders the top row as ClickableNumber
   * @returns   The ClickableNumber's to represent the top row
   */
  renderTop() {
  
    const top_display = [];
    
    for (let i = 0; i < this.props.top.length; i++) {
      
      let highlighted = false;
      if(this.props.analogy){

      if (this.props.highlighting[i] === 1) highlighted = true;
      }

      let number = this.props.top.slice(i, i + 1);
      let crossedOut, func;
    
      top_display.push(
        <Number
          key={"top" + i}
          className={"top" + i}
          number={number}
          highlighted={highlighted}
        />
      );
      
    }
    return top_display;
  }
/**
   * Configures and renders the bottom row as Number
   * @returns the Number's to represent the bottom row
   */
renderBottom() {
   

  const bottom_display = [];
  
  for (var i = 0; i < this.props.top.length ; i++) {
    let highlighted = false; 
    //only higlight for analogy
    if(this.props.analogy){
     
      if (this.props.highlighting[i] === 1) highlighted = true;
      }
  
    let number = "0";
    if (this.props.top.length - i <= this.props.bottom.length)
      number = this.props.bottom.charAt(
        this.props.bottom.length - this.props.top.length + i 
      );
    else number = "0";
   
    bottom_display.push(
      <Number
        key={"bottom" + i}
        className={"bottom" + i}
        number={number}
        highlighted={highlighted}
      />
    );
  }
  return bottom_display;
}

/**
 * Configures and renders the result row as InputNumber
 * @returns   The InputNumber's to represent the result row
 */
renderResult() {
  const result_display = [];

  for (var j = 0; j < this.props.top.length +1; j++) {  //length is +1 because the result can be one longer than the original bottom length
    let highlighted = false;
    if(this.props.analogy){
     
      if (this.props.highlighting[j] === 1) highlighted = true;
      }
    if(this.props.analogy && j == this.props.top.length ){
      break; 
    }
   // if (this.props.highlighting[j] === 1) highlighted = true;

    let number = NaN;
    let func;
    if (!this.props.analogy) {
      number = parseInt(this.state.result_row[j]);
      func = this.resultOnChange;
    } else {
      number = parseInt(this.props.result[j]);
      func = null;
    }

    result_display.push(
      <InputNumber
        key={"result" + j}
        className={"result" + j}
        number={parseInt(number)}
        error={this.state.result_row_error[j]}
        enabled={true}
        highlighted={highlighted}
        onChangeFunc={func}
       
      />
    );
  }

  return result_display;
}
  //creates a plus one button that adds one to the column
  renderPlusOne() {
    const add_buttons = [];
   
    if(!this.props.analogy){
   for (let i = 0; i < this.props.top.length +1; i++) { 
      if(this.state.add_one[i] === true){
        this.props.highlighting[i] = 1;
      }
      else{
        
        this.props.highlighting[i] = 0;
      }
    }
  }

    if (this.props.analogy) {
      return [];
    } else {
      for (let i = 0; i < this.props.top.length +1; i++) { 
        
        
        let crossedOut, func;
        let highlighted = false;
        if (this.props.highlighting[i] === 1) highlighted = true;
        crossedOut = this.state.corrections_crossedOut[i];
        func = (event) => this.top_onClick(event, i);
        
        
   
        add_buttons.push (
         <ClickableNumber
          key={"correction" + i}
          className={"correction" + i}
          highlighted={highlighted}
          crossedOut={crossedOut}
          onClickHandler={func}
          />
        );
      }
      
    
    }
    return add_buttons;
  }
  /**
   * Configures and renders the top row as ClickableNumber
   * @returns   The ClickableNumber's to represent the top row
   */
  renderCorrection() {

    const corrections_display = [];

    for (let i = 0; i < this.props.top.length ; i++) { 
      let highlighted = false;
    
      let crossedOut, func;
      if (!this.props.analogy) {
       
        crossedOut = this.state.corrections_crossedOut[i];  
      } else {
        
       // crossedOut = this.props.correction[i];
        if(this.props.correction[i] == 1){
          highlighted = true;
          crossedOut = true;
        }
        func = null;
      }
      


      

      corrections_display.push(
        <ClickableNumber
          key={"correction" + i}
          className={"correction" + i}
          
          crossedOut={crossedOut}
          onClickHandler={func}
          highlighted={highlighted}
        />
      );
      
    }
    return corrections_display;
  }

  

  /**
   * Renders a error message, if an error occurrs
   * @returns a div element with the error message, or nothing if no error occurred.
   */
  renderErrorMessage() {
    if (this.state.error_message == null) {
      return [];
    } else {
      return <div className="errorMessage">{this.state.error_message}</div>;
    }
  }

  /**
   * Renders the CorrectButton, if not in AnalogyPanel
   * @returns the CorrectButton or nothing
   */
  renderCorrectButton() {
    if (this.props.analogy) {
      return [];
    } else {
      return (
        <CorrectButton
          className="check panelControls"
          onClick={() => this.props.submit()}
        />
      );
    }
  }

  /**
   * Renders the RefreshButton, if not in AnalogyPanel
   * @returns the RefreshButton or nothing
   */
  renderRefreshButton() {
    if (this.props.analogy) {
      return [];
    } else {
      return (
        <RefreshButton
          className="refresh panelControls"
          onClick={(event) => this.reset(event)}
        />
      );
    }
  }

  /**
   * @returns the rendered AdditionPanel, depending if it represents a analogy
   */
  render() {
    
    const top_display = this.renderTop();
    const add_one_buttons = this.renderPlusOne();
    const corrections_display = this.renderCorrection();
    const bottom_display = this.renderBottom();
    const result_display = this.renderResult();
    const error_message = this.renderErrorMessage();
    const correct_button = this.renderCorrectButton();
    const refresh_button = this.renderRefreshButton();
  
    let style = [];
    if (this.props.analogy)
      style = { visibility: this.props.subpanel_visibility };

    return (
      <div className="panel" style={style}>
        <div className="grid-container">
       
        {top_display}
       
        {corrections_display}
          <div className="plus"> + </div>
          {bottom_display}
          {add_one_buttons}
          <div className="line"></div>
          {result_display}
          {correct_button}
          {refresh_button}
          {error_message}
        </div>
      </div>
    );
  }
}

AdditionPanel.propTypes = {
  bottom: PropTypes.string,
  top: PropTypes.string,
  analogy: PropTypes.bool,
  submit: PropTypes.func,
  result: PropTypes.array,
  correction: PropTypes.array,
  top_correction: PropTypes.array,
  highlighting: PropTypes.array,
  subpanel_visibility: PropTypes.string,
};
export default AdditionPanel;

import React from 'react';
import '../../CSS/Numbers.css';
import '../../CSS/ClickableNumber.css';
import '../../CSS/ColorScheme.css';
import PropTypes from 'prop-types';

/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel, Eva Jansohn
 * @date: 2021-03-30
 */
class ClickableNumber extends React.Component {
  //render the +1 button necessary for borrowing
  render() {
    const number = "+1";
    let className = 'clickableNumber roundNumber ';
    
    
    if (this.props.highlighted) {
      
      className = className + 'highlighted';
    }
  

    let x;
    
  
    return (
      <div className={this.props.className}>
        <button
          className={className}
          
          onClick={this.props.onClickHandler}
        >
        
          {number}
        </button>
        
        
      </div>
    );

  }
}
ClickableNumber.propTypes = {
  number: PropTypes.string,
  className: PropTypes.string,
  crossedOut: PropTypes.bool,
  onClickHandler: PropTypes.func,
  highlighted: PropTypes.bool,
};
export default ClickableNumber;

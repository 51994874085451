import React from 'react';
import { withRouter } from 'react-router-dom';
import InputOwnNumbers from './InputOwnNumbers.js';
import Addigator from '../../shared/Addigator.js';
import '../../CSS/OwnExercise.css';
import PropTypes from 'prop-types';

/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel, Eva Jansohn
 * @date: 2021-03-30
 */
class OwnExercise extends React.Component {

  /**
   * Gets top and bottom from InputOwnNumbers.js and
   * submits it to Calculate.js
   * @param {int} top   Top of the own exercise
   * @param {int} bottom    bottom of the own exercise
   */
  
  returnExercise(top, bottom) {
   
    this.props.returnEx(top, bottom);
  }

  render() {
    return (
      <div className="ownEx">
        {/* <Speechbubble
          text="Gib hier die Zahlen für deine eigene Aufgabe ein."
          analogy={false}
        /> */}
        <InputOwnNumbers
          submit={(tp, btm) => this.returnExercise(tp, btm)}
          close_func={this.props.close_func}
        />
        <Addigator />
      </div>
    );
  }
}

OwnExercise.propTypes = {
  returnEx: PropTypes.func,
  close_func: PropTypes.func,
};
export default withRouter(OwnExercise);

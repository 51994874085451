import analogyTexts from "../../resources/analogyTexts.json";

/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel, Eva Jansohn
 * @date: 2021-03-30
 */
export function getText(
  analogy,
  analogyTextIndex,
  correct,
  beginningBoolean,
  endBoolean,
  analogySubPanelVisibilityParam
) {
  let beginningAnalogy= beginningBoolean;
  let endAnalogy = endBoolean;
  let analogySubPanelVisibility = analogySubPanelVisibilityParam;
  let styling = [0, 0, 0, 0, 0, 0]; 
    let updateCorrectionsAndResultObj;

//arrays for corrections and reults
const arrayCross = [false, false, false, false, false, false];
const arrayCorrection = [NaN, NaN, NaN, NaN, NaN, NaN];
const arrayResult = [NaN, NaN, NaN, NaN, NaN, NaN];

  // helper Variables
  
  let tempCorrectionStep = null;
  let einerIndex = analogy.tp.length - 1;
  let zehnerIndex = analogy.tp.length - 2;
  let hunderterIndex = analogy.tp.length - 3;
  let tausenderIndex = analogy.tp.length - 4;
  let zehntausenderIndex = analogy.tp.length - 5;
  let hunderttausenderIndex = analogy.tp.length - 6;
  // variables to create correct texts
  let digit = analogyTexts.digits;
  let digit_with_n = analogyTexts.digits_with_n;

  let text = {
    correct: analogyTexts.correct,
    noCorrection: analogyTexts.noCorrection,
    startCorrection: analogyTexts.startCorrection,
    analogy: analogyTexts.analogy,
    takeTenCorrection: analogyTexts.takeTenCorrection,
    withCorrection: analogyTexts.withCorrection,
  };

 
  // returns text to display while changing the helper variables to the correct state
  let stateArray = getTextStateArray();

  // object to return to the main class to adjust variables accordingly
  let returnObject = {
    
    updateCorrectionsAndResultObj: stateArray[analogyTextIndex].upResObj,
    correct: correct,
    text: stateArray[analogyTextIndex].text,
    endAnalogy: stateArray[analogyTextIndex].endAnalogy,
    beginningAnalogy: stateArray[analogyTextIndex].beginningAnalogy,
    analogySubPanelVisibility: stateArray[analogyTextIndex].anSubPanel,
    styling: stateArray[analogyTextIndex].styling,
    einerIndex: einerIndex,
    zehnerIndex: zehnerIndex,
    hunderterIndex: hunderterIndex,
    tausenderIndex: tausenderIndex,
    zehntausenderIndex: zehntausenderIndex,
    hunderttausenderIndex: hunderttausenderIndex,
    analogy: analogy,

  };

  return returnObject;

  //"Tadaaa! Wir haben unser Ergebnis. Probiere es gleich nochmal mit deiner Aufgabe."
  function returnStringFinalResult() {
    return text.analogy[2];
  }

  //"Wir fangen bei der Einerspalte ganz rechts an. Wir rechnen top+ bottom = result"
  function startCorrectionProblem(index, res){
    var string =
      text.startCorrection +
      analogy.tp[index] +
      "\u00a0+\u00a0" +
      analogy.btm[[index]] +
      "\u00a0=\u00a0" +
      res +
      ".";
    return string;
  }

  //"Jetzt rechnen wir die ___ stelle mit top + bottom = result"
  function returnStringNoCorrection(stelle, index, result_plus_ten) {
    var string =
      text.noCorrection[0] +
      stelle +
      text.noCorrection[1] +
      analogy.tp[index] +
      "\u00a0+\u00a0" +
      analogy.btm[index] +
      "\u00a0=\u00a0" +
      result_plus_ten +
      ".";
    return string;
  }

  //"Da result größer is 9 rechnen wir result - 10 = res-10"
  function returnTakeTen(stelle, resPlusZehn, res){
    var string =
      text.takeTenCorrection[0] +
      resPlusZehn +
      text.takeTenCorrection[1] +
      resPlusZehn +
      text.takeTenCorrection[2] +
      res+
      ". "+
      text.takeTenCorrection[3]+
      digit[stelle]+
      text.takeTenCorrection[4]+
      digit[stelle-1]+
      text.takeTenCorrection[5]+
      digit_with_n[stelle]+
      ".";
    return string;
  }

  //"Jetzt rechnen wir die stelle. Da wir einen Stelle erhalten haben, rechnen wir nun top + bottom + 1 = result"
  function returnWithCorrection(stelle, index, res){
    var string =
      text.withCorrection[0]+
      stelle+
      text.withCorrection[1]+
      analogy.tp[index]+
      "\u00a0+\u00a0" +
      analogy.btm[index]+
      "\u00a0+\u00a0" +
      "1"+
      "\u00a0=\u00a0" +
      res +
      ".";
      


    return string;
  }
 
  
  function updateCorrectionsAndResult(
    arrayCross,
    arrayCorrection,
    arrayResult
  ) {
    // create update object to return to main class
  
    updateCorrectionsAndResultObj = {
      
      crossHunderttausender: typeof arrayCross[5] === "undefined"
      ? false
      : arrayCross[5],
      crossZehntausender: typeof arrayCross[4] === "undefined"
      ? false
      : arrayCross[4],
      crossTausender: typeof arrayCross[3] === "undefined"
      ? false
      : arrayCross[3],
      crossHunderter: typeof arrayCross[2] === "undefined"
      ? false
      : arrayCross[2],
      crossZehner: typeof arrayCross[1] === "undefined"
      ? false
      : arrayCross[1],
      crossEiner: typeof arrayCross[0] === "undefined"
      ? false
      : arrayCross[0],
      corHunderttausender: typeof arrayCorrection[5] === "undefined"
      ? NaN
      : arrayCorrection[5],
      corZehntausender: typeof arrayCorrection[4] === "undefined"
      ? NaN
      : arrayCorrection[4],
      corTausender: typeof arrayCorrection[3] === "undefined"
      ? NaN
      : arrayCorrection[3],
      corHunderter: typeof arrayCorrection[2] === "undefined"
      ? NaN
      : arrayCorrection[2],
      corZehner: typeof arrayCorrection[1] === "undefined"
      ? NaN
      : arrayCorrection[1],
      corEiner: typeof arrayCorrection[0] === "undefined"
      ? NaN
      : arrayCorrection[0],
      resHunderttausender: typeof arrayResult[5] === "undefined"
      ? NaN
      : arrayResult[5],
      resZehntausender: typeof arrayResult[4] === "undefined"
      ? NaN
      : arrayResult[4],
      resTausender: typeof arrayResult[3] === "undefined"
      ? NaN
      : arrayResult[3],
      resHunderter: typeof arrayResult[2] === "undefined"
      ? NaN
      : arrayResult[2],
      resZehner: typeof arrayResult[1] === "undefined"
      ? NaN
      : arrayResult[1],
      resEiner: typeof arrayResult[0] === "undefined"
      ? NaN
      : arrayResult[0],
      
   
    };
    updateCorrectionsAndResultObj = JSON.parse(
      JSON.stringify(updateCorrectionsAndResultObj)
    );
    
    return updateCorrectionsAndResultObj;
  }


  //creates slides showing the analogy
  function getTextStateArray(){
   
    var output;
    
    var digit_counter = 0; //count digitits in output because the analogy counts high to low and the arrays count from 0 up
    var textForExport = [];
    
    let emptyUpResObj =  {
        //add hundert tausender spalte
      crossHunderttausender: false,
      crossZehntausender: false,
      crossTausender: false,
      crossHunderter: false,
      crossZehner: false,
      crossEiner: false,
      corHunderttausender: NaN,
      corZehntausender: NaN,
      corTausender: NaN,
      corHunderter: NaN,
      corZehner: NaN,
      corEiner: NaN,
      resHunderttausender: NaN,
      resZehntausender: NaN,
      resTausender: NaN,
      resHunderter: NaN,
      resZehner: NaN,
      resEiner: NaN,
    };
    
    var resObj = emptyUpResObj;
    
    //when the student result is false and an analogy is shown
    if(!correct){
   
      //is a take_ten and increase operation necessary?
      var resLessThan10 = false;
     
    //loop through the analogy
    for(let i = analogy.btm.length - 1; i >= -1 ; i--){
     
      styling=[0,0,0,0,0,0];
      resObj = emptyUpResObj;
      
      
      //is a take_ten and increase operation necessary?
   
      resLessThan10 = false;
   
      //if top plus bottom (plus one if applicable) is less than ten
      if((  arrayCross[digit_counter] == false && (+analogy.tp[i] + +analogy.btm[i]) < 10) || (arrayCross[digit_counter] == true && (+analogy.tp[i] + +analogy.btm[i] +1) < 10)){

        resLessThan10 = true;
       
      }
      
      
      //make introdution slides
      if(i == analogy.btm.length-1){

        //first slide of introduction
        beginningAnalogy = true;
        endAnalogy = false;
        analogySubPanelVisibility = "hidden";
        textForExport.push({text: text.analogy[0],beginningAnalogy: true, endAnalogy: false,  upResObj: updateCorrectionsAndResult(arrayCross, arrayCorrection, arrayResult), anSubPanel: analogySubPanelVisibility, styling: styling});
        
        
        //second slide of introduction
        beginningAnalogy = false;
        
   
   
        textForExport.push({text: text.analogy[1], endAnalogy: false, upResObj: updateCorrectionsAndResult(arrayCross, arrayCorrection, arrayResult), anSubPanel: analogySubPanelVisibility, styling: styling});
     

        
        //answer is greater than 10 which needs to e displayed in first step
        if(resLessThan10 == false){
          
          let result_plus_ten = +analogy.res[i] +10;
          output = startCorrectionProblem(i, result_plus_ten);
          arrayResult[digit_counter] = +analogy.res[i] + 10;
          
        }
        //answer less than 10, normal addition step
        else{
          output = startCorrectionProblem(i, analogy.res[i]);
          //resObj = updateCorrectionsAndResult(arrayCross, arrayCorrection, arrayResult);
          arrayResult[digit_counter] = analogy.res[i];
        }
               
       
        styling[i] = 1;
   
        textForExport.push({text: output, endAnalogy:false, upResObj: updateCorrectionsAndResult(arrayCross, arrayCorrection, arrayResult), styling: styling});
       
        //return textForExport;
        

      }    
      else if (i == -1){
     
      //make text for final result
      output = returnStringFinalResult();
      analogySubPanelVisibility = 'hidden';
      //update arrow
      endAnalogy = true;
     
     
     
     textForExport.push({text: output, endAnalogy: true, upResObj: updateCorrectionsAndResult(arrayCross, arrayCorrection, arrayResult), styling: styling, anSubPanel: analogySubPanelVisibility});
     break;
      } 

      //no additional 1 was added to this column
      else if(arrayCross[digit_counter] == false){
        
        //this column is greater or equal to 10
        if(resLessThan10 == false){
         
          let result_plus_ten = +analogy.res[i] +10;
          output = returnStringNoCorrection(digit[digit_counter], i, result_plus_ten);
          arrayResult[digit_counter] = +analogy.res[i] + 10;

          
        }
        //this column is less than 10
        else{
          
          output = returnStringNoCorrection(digit[digit_counter], i, analogy.res[i]);
          arrayResult[digit_counter] = analogy.res[i];
          
        }
               
       
        styling[i] = 1;
        
        textForExport.push({text: output, upResObj:  updateCorrectionsAndResult(arrayCross, arrayCorrection, arrayResult), styling: styling})
        
      

      }
      //no additional 1 added to this column 
      else{
       
       // column is greater or equal 10
        if(resLessThan10 == false){
          let result_plus_ten = +analogy.res[i] +10;
          output = returnWithCorrection(digit[digit_counter], i, result_plus_ten);
          arrayResult[digit_counter] = +analogy.res[i] + 10;
        }
        //column is less than 10
        else{
          output = returnWithCorrection(digit[digit_counter], i, analogy.res[i]);
          
          arrayResult[digit_counter] = analogy.res[i];
        }
               
        
        styling[i] = 1;
    
        textForExport.push({text: output, upResObj: updateCorrectionsAndResult(arrayCross, arrayCorrection, arrayResult), styling: styling})
      
      }
      styling = [0,0,0,0,0,0];

      //if the column is greater or equal 10 an additional slide shows the 10 being subtracted from the result and 1 being added to the column to the left
      if(resLessThan10 == false){
        
        arrayCorrection[digit_counter+1] = 1;
          let result_plus_ten = +analogy.res[i] +10;
          arrayResult[digit_counter] = analogy.res[i];
          if(digit_counter < analogy.btm.length){
          arrayCross[digit_counter +1] = true;}
          arrayCorrection[digit_counter + 1] = 1;
          styling[i] = 1
          styling[i-1] = 1;
         
          
          output = returnTakeTen( digit_counter +1, result_plus_ten, analogy.res[i]);
          
          textForExport.push({text: output, upResObj: updateCorrectionsAndResult(arrayCross, arrayCorrection, arrayResult), styling: styling});


      }
      styling = [0,0,0,0,0,0];

      

  
      digit_counter++;
   }



    


  }
  //no analogy needed, the answer is correct
  else{
    
    styling = [0,0,0,0,0,0];
    output = text.correct;
  textForExport.push({text: output[0], endAnalogy : true, beginningAnalogy: true, upResObj: emptyUpResObj, anSubPanel: 'hidden', styling: styling})
    return textForExport;
  }

  return textForExport;

 }
 
}










